<template>
  <div v-bind:class="expanded ? 'feed-expanded' : 'feed-not-expanded'">
    <div
      class="exit"
      @click="
        () => {
          expanded = !expanded;
        }
      "
    >
      <Exit></Exit>
    </div>

    <div
      ref="cityData"
      v-bind:class="expanding ? 'transition-none' : ''"
      class="page-container mobile-feed hidden-xs-only"
      v-on:touchstart="handleTouchStart"
      v-on:touchmove="handleTouch"
      v-on:touchend="handleTouchEnd"
    >
      <div v-if="trips" class="list-container">
        <div :key="'details' + index" v-for="(trip, index) in trips">
          <div
            v-if="index < activeIndex - 1"
            :style="{
              height: imageHeight,
              marginTop:
                index === 0
                  ? 'calc(' +
                    activeIndex * -100 +
                    'vh' +
                    ' + ' +
                    movePercentage * 100 +
                    '%' +
                    ' + ' +
                    activeIndex * headerHeight +
                    'px)'
                  : 0,
            }"
          ></div>
          <city-section
            @click="
              () => {
                expanded = true;
              }
            "
            class="city-section"
            :style="{
              marginTop:
                index === 0
                  ? 'calc(' +
                    activeIndex * -100 +
                    'vh' +
                    ' + ' +
                    movePercentage * 100 +
                    '%' +
                    ' + ' +
                    activeIndex * headerHeight +
                    'px)'
                  : 0,
            }"
            :city="null"
            :experiences="[trip]"
            v-else-if="index <= activeIndex + 1"
            :maxPrice="maxAvailablePrice"
            :active="index === activeIndex"
            :minPrice="minAvailablePrice"
            :height="imageHeight"
            :minFlightPrice="
              typeof cityMinimumFlightPrices[index] === 'number'
                ? cityMinimumFlightPrices[index]
                : null
            "
            :flightDuration="cityMinimumFlightDuration[index]"
          />
        </div>
      </div>
      <div v-else class="list-container">
        <div
          :key="'details' + city.city_id + index"
          v-for="(city, index) in displayCities"
          :ref="city.city_id"
        >
          <div
            v-if="index < activeIndex - 1"
            :style="{
              height: imageHeight,
              marginTop:
                index === 0
                  ? 'calc(' +
                    activeIndex * -100 +
                    'vh' +
                    ' + ' +
                    movePercentage * 100 +
                    '%' +
                    ' + ' +
                    activeIndex * headerHeight +
                    'px)'
                  : 0,
            }"
          ></div>
          <city-section
            @click="
              () => {
                expanded = true;
              }
            "
            class="city-section"
            :style="{
              marginTop:
                index === 0
                  ? 'calc(' +
                    activeIndex * -100 +
                    'vh' +
                    ' + ' +
                    movePercentage * 100 +
                    '%' +
                    ' + ' +
                    activeIndex * headerHeight +
                    'px)'
                  : 0,
            }"
            :city="city"
            :experiences="city.experiences"
            v-else-if="index <= activeIndex + 1"
            :maxPrice="maxAvailablePrice"
            :active="index === activeIndex"
            :minPrice="minAvailablePrice"
            :height="imageHeight"
            :minFlightPrice="
              typeof cityMinimumFlightPrices[index] === 'number'
                ? cityMinimumFlightPrices[index]
                : null
            "
            :flightDuration="cityMinimumFlightDuration[index]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import CitySection from '@/views/destinationFeed/CitySectionMobile';
import cities from '@/assets/cities.json';
import interests from '@/assets/interests.json';
// import { searchFlightsApi } from '@/api/flights';
import { getExperiencesByCategoryApi } from '@/api/firebase';
import Exit from '@/assets/destinationPage/icons/exit.svg';

export default {
  name: 'MobileFeed',
  components: {
    CitySection,
    Exit,
  },
  data: function() {
    return {
      activeIndex: parseInt(this.$route.params.index),
      expanded: false,
      loadedExperiences: [],
      imagesLoaded: false,
      loaderTimeOut: false,
      trips: null,
      touchstartX: null,
      touchstartY: null,
      movePercentage: 0,
      loaderTimer: null,
      cities: cities.map(city => ({ text: city, value: city })),
      interests: interests,
      seasons: [ 'Winter Escape', 'Follow The Sun' ],
      selectedCity: '',
      cityCoordinates: {},
      cityCoordinatesLoaded: false,
      originCoordinates: { lat: 0, lng: 0 },
      originCityCountry: '',
      activeCityIndex: 0,
      cardCityIndex: undefined,
      activeFilter: '',
      airports: null,
      filters: {
        DATE: 'DATE',
        PRICE: 'PRICE',
        DISTANCE: 'DISTANCE',
        INTERESTS: 'INTERESTS',
      },
      priceFilterRange: [ 0, 10000 ],
      selectedInterests: [],
      selectedSeason: [],
      dateRange: [],
      headerHeight: 100,
      cityMinimumFlightPrices: [],
      cityMinimumFlightDuration: [],
      season: this.$route.query.season,
      winterFilter: false,
      summerFilter: false,
      showLoader: true,
      expanding: false,
      searchedCount: 0,
    };
  },
  computed: {
    imageHeight: {
      get() {
        return 'calc(100vh - ' + this.headerHeight + 'px)';
      },
    },
    ...mapGetters([ 'experiences', 'topExperiences' ]),
    interestChain() {
      return this.selectedInterests
        .map(interest => this.interests.indexOf(interest) + 1)
        .sort((a, b) => a - b)
        .join('-');
    },
    maxAvailablePrice() {
      return this.priceFilterRange[1];
    },
    minAvailablePrice() {
      return this.priceFilterRange[0];
    },
    autoCompleteStyle() {
      return `width: ${this.selectedCity.length * 1.02}ch;`;
    },
    displayCities() {
      const result = this.loadedExperiences
        .map(city => {
          const filteredExpereincesByPrice = city.experiences.filter(
            experience => {
              const totalHotelsPrice =
                experience.hotels?.reduce(
                  (accumulator, hotel) => accumulator + hotel.price,
                  0
                ) ?? 0;

              if (this.winterFilter)
                return (
                  experience.season === 'winter' &&
                  this.summerFilter == false &&
                  totalHotelsPrice >= this.minAvailablePrice &&
                  totalHotelsPrice <= this.maxAvailablePrice
                );
              if (this.summerFilter)
                return (
                  experience.season === 'summer' &&
                  this.winterFilter == false &&
                  totalHotelsPrice >= this.minAvailablePrice &&
                  totalHotelsPrice <= this.maxAvailablePrice
                );
              return (
                totalHotelsPrice >= this.minAvailablePrice &&
                totalHotelsPrice <= this.maxAvailablePrice
              );
            }
          );
          return {
            ...city,
            experiences: filteredExpereincesByPrice,
          };
        })
        .filter(city => city.experiences.length > 0);
      return result;
    },
    activeCityCoordinates() {
      return this.displayCities.length > 0
        ? {
          lat: this.displayCities[this.activeCityIndex].coordinates_lat + 8,
          lng: this.displayCities[this.activeCityIndex].coordinates_lng ?? 0,
        }
        : {
          lat: 0,
          lng: 0,
        };
    },
    initialCityCoordinates() {
      return this.displayCities.length > 0
        ? {
          lat: this.displayCities[0].coordinates_lat + 8,
          lng: this.displayCities[0].coordinates_lng ?? 0,
        }
        : {
          lat: 0,
          lng: 0,
        };
    },
    dateText() {
      return `• ${
        this.dateRange[0]
          ? new Date(this.dateRange[0]).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          : ''
      } - ${
        this.dateRange[1]
          ? new Date(this.dateRange[1]).toLocaleDateString('en-GB', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })
          : ''
      }`;
    },
  },
  watch: {
    async originCoordinates(val) {
      const response = await axios.get('https://api.skypicker.com/locations', {
        params: {
          type: 'radius',
          lat: val.lat,
          lon: val.lng,
          location_types: 'airport',
          limit: 3,
        },
      });
      this.airports = response.data.locations.map(airport => airport.code);
    },
    expanded(val) {
      this.expanding = true;
      this.$nextTick(() => {
        if (val == true) {
          this.headerHeight = 0;
        } else {
          this.headerHeight = 100;
        }
        this.$nextTick(() => {
          window.setTimeout(() => {
            this.expanding = false;
          }, 300);
        });
      });
    },
    displayCities() {},
    experiences(val) {
      this.showLoader = true;
      this.startLoaderTimer();
      val.length && this.imagesLoadingHandler(val);
      this.loadedExperiences = [ ...val ];
    },
    selectedCity(val) {
      this.$router.push({ query: { ...this.$route.query, city: val } });
      this.getOriginCityCoordinates();
    },
    dateRange(val) {
      this.$store.commit('search/setParams', {
        from: val[0],
        to: val[1],
      });
      localStorage.dateRange = JSON.stringify(val);
    },
    originCityCountry(val) {
      this.$store.commit('search/setParams', {
        country: val.slice(2),
      });
    },
    activeFilter(val) {
      localStorage.activeFilter = val;
    },
    priceFilterRange(val) {
      localStorage.priceFilterRange = JSON.stringify(val);
    },
    winterFilter(val) {
      localStorage.winterFilter = JSON.stringify(val);
    },
    summerFilter(val) {
      localStorage.summweFilter = JSON.stringify(val);
    },
    activeIndex(val) {
      history.replaceState(
        {},
        null,
        '/' + this.$route.name + '/' + this.$route.params.search + '/' + val
      );
    },
  },
  async created() {
    const startDate = new Date();
    startDate.setDate(startDate.getDate() + 30);

    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 44);

    this.dateRange = [
      startDate.toISOString().slice(0, 10),
      endDate.toISOString().slice(0, 10),
    ];

    if (this.$route.params.search.startsWith('category:')) {
      this.trips = await getExperiencesByCategoryApi(
        this.$route.params.search.replace('category:', '')
      );
    }

    this.loadCities();
    this.getOriginCityCoordinates();
    await this.$gmapApiPromiseLazy();
  },
  async mounted() {
    if (localStorage.activeFilter)
      this.activeFilter = localStorage.activeFilter;
    if (localStorage.priceFilterRange)
      this.priceFilterRange = JSON.parse(localStorage.priceFilterRange);
    if (localStorage.dateRange)
      this.dateRange = JSON.parse(localStorage.dateRange);

    //if (localStorage.winterFilter) this.winterFilter = JSON.parse(localStorage.winterFilter);
  },
  beforeDestroy() {
    this.stopLoaderTimer();
  },
  methods: {
    ...mapActions([ 'loadExperiences', 'loadCities' ]),

    handleBookCity(name, mode) {
      this.$router.push({
        path: '/destination/' + name,
        query: { mode, ...this.$route.query },
      });
    },
    showCityDetails(index) {
      this.activeCityIndex = index;
      this.cardCityIndex = undefined;
      this.$refs.map.panTo({
        lat: this.displayCities[index].coordinates_lat,
        lng: this.displayCities[index].coordinates_lng ?? 0,
      });
    },
    scrollToCity(cityId, index) {
      const element = this.$refs[cityId][0];
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });

      this.activeCityIndex = index;
      this.cardCityIndex = index;
    },
    getOriginCityCoordinates() {
      const addressObject = {
        address_line_1: '',
        address_line_2: '',
        city: this.selectedCity,
        state: '',
        zip_code: '',
        country: '',
      };
      this.$geocoder.send(addressObject, response => {
        this.originCoordinates = response?.results[0]?.geometry.location;
        this.originCityCountry = response?.results[0]?.formatted_address.split(
          ', '
        )[1]
          ? `, ${response?.results[0]?.formatted_address.split(', ')[1]}`
          : '';
      });
    },
    getCityCoordinates() {
      this.displayCities.map(experience => {
        const addressObject = {
          address_line_1: '',
          address_line_2: '',
          city: experience.name,
          state: '', // province also valid
          zip_code: '', // postal_code also valid
          country: experience.country,
        };
        this.$geocoder.send(addressObject, response => {
          this.cityCoordinates[experience.city_id] = {
            position: response?.results[0]?.geometry.location,
            name: response?.results[0]?.formatted_address,
          };
        });
      });
    },

    handleTouchStart(event) {
      this.touchstartX = event.changedTouches[0].clientX;
      this.touchstartY = event.changedTouches[0].clientY;
    },
    handleTouch(event) {
      if (this.touchstartY === null) {
        return;
      }
      let touchY = event.changedTouches[0].clientY;
      let percentageMoved = (touchY - this.touchstartY) / window.innerHeight;
      this.movePercentage = percentageMoved;
    },
    handleTouchEnd(event) {
      let touchY = event.changedTouches[0].clientY;
      let percentageMoved = (touchY - this.touchstartY) / window.innerHeight;

      if (percentageMoved > 0.2) {
        if (this.activeIndex > 0) {
          this.activeIndex--;
          this.touchstartY = null;
          this.movePercentage = null;
        }
      }

      if (percentageMoved < -0.2) {
        if (
          this.activeIndex + 1 <
          (this.trips ? this.trips.length : this.displayCities.length)
        ) {
          this.activeIndex++;
          this.touchstartY = null;
          this.movePercentage = null;
        }
      }

      this.movePercentage = null;
    },
    async imagesLoadingHandler(cities) {
      const loadBackgroundImage = object => {
        return new Promise((resolve, reject) => {
          const src = object.background_image;
          const img = new Image();
          img.onload = () => {
            object.background_image = null;
            this.$nextTick(() => {
              object.background_image = src;
            });
            resolve(object);
          };
          img.onerror = () => {
            object.background_image = null;
            this.$nextTick(() => {
              object.background_image = src;
            });
            reject(object);
          };
          img.src = src;
        });
      };

      const promises = cities
        .map(city => {
          return city.experiences.map(experience =>
            loadBackgroundImage(experience)
          );
        })
        .flat()
        .slice(0, 5);

      return Promise.allSettled(promises)
        .then(() => {
          this.showLoader = false;
        })
        .finally(() => {
          this.showLoader = false;
          console.log(this.showLoader); // => 'not updated'
          this.$nextTick(function() {
            this.showLoader = false;
            console.log(this.showLoader); // => 'updated'
          });
        });
    },
    startLoaderTimer() {
      this.loaderTimer = setTimeout(() => {
        this.showLoader = false;
        this.stopLoaderTimer();
      }, 3000);
    },
    stopLoaderTimer() {
      this.loaderTimer && clearTimeout(this.loaderTimer);
      this.loaderTimer = null;
    },
  },
};
</script>

<style lang="scss">
header {
  position: fixed !important;
  background: white;
}
</style>

<style lang="scss" scoped>
.feed-expanded {
  .page-container::v-deep {
    min-height: calc(100vh) !important;
    max-height: calc(100vh) !important;
    z-index: 99;
    position: fixed;
  }
}
.page-container::v-deep {
  display: flex;
  width: 100%;
  overflow: hidden;
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  flex-direction: row;
  background: #000;

  img {
    object-fit: cover;
  }
  .list-container {
    width: 100%;
    background: #242424;
    scrollbar-width: none; /* Firefox */
    .filter-container {
      padding: 2.5vw 4.5vw 2.1vw 3.1vw;
      margin-bottom: -1.5625vw;
      .origin-title {
        color: #b0b0b2;
        font-family: MontserratRegular;
        font-size: 1.25vw;
        display: flex;
        span {
          width: fit-content;
        }
        .underline {
          text-decoration: underline;
          margin-left: -0.26vw;
        }
      }
      .cta-explore-options {
        color: #28262e;
        font-family: PoppinsSemiBold;
        font-size: 2.5vw;
        margin: 0.83vw 0 0.72vw;
      }
      .filter-list {
        display: flex;
        flex-direction: row;
        margin-top: 1.56vw;
        .active {
          border: 1px solid black;
        }
        .filter-item {
          height: 3.02vw;
          border-radius: 1.5vw;
          margin-right: 1.3vw;
          background-color: #f1f1f1;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: MontserratRegular;
          font-size: 0.9vw;
          color: #28262e;
          position: relative;
          cursor: pointer;
          box-sizing: border-box;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          .label {
            height: 100%;
            width: 100%;
            padding: 0 2.08vw;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .label::-moz-selection {
            background: none;
          }
          .label::selection {
            background: none;
          }
          .date-filter {
            position: absolute;
            z-index: 150;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
            border-radius: 1.041vw;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            .filter-content {
              .v-picker {
                font-family: PoppinsRegular;
                width: 290px !important;
                background: transparent;
                border: none;
                .v-picker__body {
                  overflow: hidden;
                  border: none;
                }
                .v-date-picker-header {
                  padding: 0 1.458vw;
                  font-size: 0.937vw;
                  margin: 20px 0;
                  .v-btn {
                    background-color: #f2f2f2;
                    height: 1.041vw;
                    width: 1.041vw;
                    .v-btn__content > .v-icon {
                      height: 0.625vw;
                      width: 0.625vw;
                      font-size: 0.625vw;
                      color: black;
                    }
                  }
                }
                .v-date-picker-table {
                  font-family: PoppinsRegular;
                  padding: 0 1.458vw;
                  margin-bottom: 0.735vw;
                  height: 10.73vw;
                  th {
                    font-weight: 500;
                    color: #595959;
                  }
                }
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .price-filter {
            position: absolute;
            z-index: 100;
            width: 360px;
            height: 297px;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 24px;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 16px;
                color: #28262e;
                margin-bottom: 6px;
              }
              .average {
                font-family: MontserratRegular;
                font-size: 12px;
                line-height: 16px;
                /* Paragraph */
                color: #747378;
              }
              .v-input--range-slider {
                margin-bottom: 16px;
                .v-slider__track-container {
                  height: 4px;
                }
                .v-slider__thumb-container {
                  .v-slider__thumb {
                    background: white !important;
                    width: 20px;
                    height: 20px;
                    border: 1px solid #d62a53;
                  }
                  .v-slider__thumb::before {
                    display: none;
                  }
                }
                .v-slider__thumb-label-container {
                  top: 44px;
                  left: -12px;
                  background: white;
                  .v-slider__thumb-label {
                    transform: none !important;
                    background: white !important;
                    font-family: MontserratRegular;
                    color: #747378;
                    .label {
                      display: flex;
                      flex-direction: row;
                      width: 60px;
                      padding: 0;
                      background-color: white;
                    }
                    * {
                      transform: none;
                    }
                  }
                }
              }
              .input-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .dash {
                  width: 12px;
                  height: 1px;
                  background: #8e8d92;
                }
                .v-input.v-text-field--filled {
                  max-width: 140px;
                  background: white !important;
                  border-radius: 10px;
                  transform: none;
                  .v-input__control {
                    height: 56px;
                    .v-input__slot {
                      background: white;
                      border: 1px solid #e7e7e7;
                      .v-text-field__slot {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        label.v-label {
                          position: relative !important;
                          top: 20px;
                          font-family: MontserratRegular;
                          font-size: 15px !important;
                          line-height: 12px;
                          font-style: normal;
                          color: #8e8d92;
                        }
                        input {
                          margin-top: 0;
                          padding: 0;
                          font-family: MontserratSemiBold;
                          font-size: 16px;
                          line-height: 14px;
                          color: #28262e;
                        }
                      }
                    }
                    .v-input__slot::before {
                      display: none;
                    }
                    .v-input__slot::after {
                      display: none;
                    }
                  }
                }
              }
            }
            .filter-dialog {
              padding: 12px 24px 24px;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 12px;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 36px;
                width: 77px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .interests-badge {
            color: white;
            position: absolute;
            background: #d62a53;
            height: 1vw;
            width: 1vw;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1vw;
            top: 0;
            right: 0;
            font-size: 0.625vw;
          }
          .interest-filter {
            position: absolute;
            z-index: 1000;
            width: 360px;
            min-height: 297px;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0.625vw 1.25vw rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 1.25vw;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 0.833vw;
                color: #28262e;
                margin-bottom: 0.3125vw;
              }
              .v-input--selection-controls {
                margin-top: 0;
              }
              .v-messages {
                display: none;
              }
              label {
                font-family: MontserratRegular;
                font-size: 0.729vw;
                line-height: 0.729vw;
                letter-spacing: -0.02em;
                color: #28262e;
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .season-filter {
            position: absolute;
            z-index: 1000;
            width: 14vw;
            min-height: 12vw;
            top: 3.12vw;
            left: -2px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 0.625vw 1.25vw rgba(0, 0, 0, 0.06);
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .filter-content {
              padding: 1.25vw;
              .title {
                font-family: PoppinsSemiBold;
                font-size: 0.833vw;
                color: #28262e;
                margin-bottom: 0.3125vw;
              }
              .v-input--selection-controls {
                margin-top: 0;
              }
              .v-messages {
                display: none;
              }
              label {
                font-family: MontserratRegular;
                font-size: 0.729vw;
                line-height: 0.729vw;
                letter-spacing: -0.02em;
                color: #28262e;
              }
            }
            .filter-dialog {
              padding: 0.625vw 1.25vw 1.25vw;
              border-top: 1px solid #e5e5e5;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-family: MontserratSemiBold;
              font-size: 0.625vw;
              letter-spacing: -0.02em;
              cursor: pointer;
              .clear {
                color: #747378;
              }
              .save {
                color: white;
                background: #d62a53;
                border-radius: 1.875vw;
                width: 4.01vw;
                height: 1.66vw;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          .winter-badge {
            position: absolute;
            background-image: url('../../../assets/destinationPage/winterIcon.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: 1.3vw;
            width: 1.3vw;
            top: -0.3125vw;
            right: -0.3125vw;
          }
          .summer-badge {
            position: absolute;
            background-image: url('../../../assets/destinationPage/summerIcon.png');
            background-repeat: no-repeat;
            background-size: contain;
            height: 1.3vw;
            width: 1.3vw;
            top: -0.3125vw;
            right: -0.3125vw;
          }
        }
      }
    }
  }
  .list-container::-webkit-scrollbar {
    display: none;
  }
}
.v-btn--is-elevated {
  box-shadow: unset !important;
}
.teaser-select {
  z-index: 150;
}

.city-section {
  transition: 0.3s;
}

.transition-none {
  transition: none !important;
  * {
    transition: none !important;
  }
}

.feed-not-expanded {
  margin-top: 100px;
}

.exit {
  z-index: 100;
  color: white;
  position: fixed;
  top: 24px;
  right: 24px;
  width: 24px;
  height: 24px;
}
.feed-not-expanded .exit {
  display: none;
}
</style>
