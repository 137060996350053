<template>
  <div
    :class="{ hide: displayExperiences.length < 1 }"
    class="city-section hidden-xs-only"
    :style="{
      height: height,
    }"
  >
    <div class="city-experiences-container tw-relative">
      <template v-if="displayExperiences.length > 0">
        <template v-if="false">
          <v-img
            v-if="storyItems[0].type == 'image'"
            class="v-img"
            :style="{
              height: height,
            }"
            :src="transformImage(storyItems[0].url)"
          >
          </v-img>
          <video
            v-if="storyItems[0].type == 'video'"
            class="v-img"
            preload
            :style="{
              height: height,
            }"
            :src="optimizeVideo(storyItems[0].url)"
          ></video>
        </template>

        <div>
          <Story
            :isMobile="true"
            :showButtons="false"
            :items="storyItems"
            :storyId="displayExperiences[0].exp_id"
            ref="story"
            :active="active"
            @click="e => $emit('click', e)"
            class="story"
            :influencer="displayExperiences[0].influencer_data"
            :expandStoryCarouselEnabled="false"
            @toggle-expand="() => {}"
          />
        </div>

        <div class="description-left">
          <div class="title">{{ displayExperiences[0].name }}</div>
          <div class="location">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4922 4.99805C10.4922 8.49805 5.99219 11.498 5.99219 11.498C5.99219 11.498 1.49219 8.49805 1.49219 4.99805C1.49219 3.80457 1.96629 2.65998 2.81021 1.81607C3.65412 0.972153 4.79871 0.498047 5.99219 0.498047C7.18566 0.498047 8.33025 0.972153 9.17417 1.81607C10.0181 2.65998 10.4922 3.80457 10.4922 4.99805Z"
                stroke="white"
                stroke-opacity="0.9"
                stroke-width="0.82153"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M5.99219 6.49805C6.82061 6.49805 7.49219 5.82647 7.49219 4.99805C7.49219 4.16962 6.82061 3.49805 5.99219 3.49805C5.16376 3.49805 4.49219 4.16962 4.49219 4.99805C4.49219 5.82647 5.16376 6.49805 5.99219 6.49805Z"
                stroke="white"
                stroke-opacity="0.9"
                stroke-width="0.82153"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{
              city
                ? city.name + ', ' + city.country
                : displayExperiences[0].city_name +
                  ', ' +
                  displayExperiences[0].country
            }}
          </div>
        </div>
        <v-btn
          class="discover-btn v-btn"
          @click="
            $router.push({
              name: 'MobilePackage',
              params: { experienceId: displayExperiences[0].exp_id },
            })
          "
          >Discover Trip</v-btn
        >
      </template>
      <!-- <carousel v-else>
        <rl-carousel-slide
          v-for="item in displayExperiences"
          :key="item.description"
          class="slide tw-relative"
          :style="{
            height: height,
          }"
        >
          <v-img
            @click="e => $emit('click', e)"
            class="v-img"
            :style="{
              height: height,
            }"
            :src="transformImage(item.background_image)"
          >
          </v-img>

          <div class="description-left">
            <div class="title">{{ item.name }}</div>
            <div class="location">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.4922 4.99805C10.4922 8.49805 5.99219 11.498 5.99219 11.498C5.99219 11.498 1.49219 8.49805 1.49219 4.99805C1.49219 3.80457 1.96629 2.65998 2.81021 1.81607C3.65412 0.972153 4.79871 0.498047 5.99219 0.498047C7.18566 0.498047 8.33025 0.972153 9.17417 1.81607C10.0181 2.65998 10.4922 3.80457 10.4922 4.99805Z"
                  stroke="white"
                  stroke-opacity="0.9"
                  stroke-width="0.82153"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.99219 6.49805C6.82061 6.49805 7.49219 5.82647 7.49219 4.99805C7.49219 4.16962 6.82061 3.49805 5.99219 3.49805C5.16376 3.49805 4.49219 4.16962 4.49219 4.99805C4.49219 5.82647 5.16376 6.49805 5.99219 6.49805Z"
                  stroke="white"
                  stroke-opacity="0.9"
                  stroke-width="0.82153"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {{ city.name + ', ' + city.country }}
            </div>
          </div>

          <v-btn class="discover-btn" @click="$router.push(`mobilepackage/story/${item.exp_id}`)"
            >Discover Trip</v-btn
          >
        </rl-carousel-slide>
      </carousel> -->
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { transformImage } from '@/utils/transformImage';
// import { RlCarouselSlide } from 'vue-renderless-carousel';
// import Carousel from './FeedCarousel.vue';
import Story from '@/views/inspiration/insta-story/InstaStory.vue';
import { optimizeVideo } from '@/utils/optimizeVideo';

export default {
  name: 'CitySection',
  data() {
    return {
      swiperOption: {
        itemsToShow: 3,
        shortDrag: false,
        mouseDrag: false,
        touchDrag: false,
        wheelControl: false,
      },
      showButton: false,
      readMore: false,
    };
  },
  props: [
    'city',
    'experiences',
    'active',
    'minPrice',
    'maxPrice',
    'minFlightPrice',
    'flightDuration',
    'height',
  ],
  computed: {
    storyItems() {
      return this.displayExperiences[0].story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image',
        };
      });
    },
    isFeed() {
      return this.$route.name === 'DestinationFeed';
    },
    totalActivitiesPricePerExperience() {
      return this.experiences.map(experience => {
        return (
          experience.activities?.reduce(
            (accumulator, activity) => accumulator + activity.price,
            0
          ) ?? 0
        );
      });
    },
    totalHotelsPricePerExperience() {
      return this.experiences.map(experience => {
        return (
          experience.hotels?.reduce(
            (accumulator, hotel) => accumulator + hotel.price,
            0
          ) ?? 0
        );
      });
    },
    totalRestaurantsPricePerExperience() {
      return this.experiences.map(experience => {
        return (
          experience.restaurants?.reduce(
            (accumulator, restaurant) => accumulator + restaurant.price,
            0
          ) ?? 0
        );
      });
    },
    totalPricePerExperience() {
      return this.experiences.map(
        (experience, index) =>
          this.totalActivitiesPricePerExperience[index] +
          this.totalHotelsPricePerExperience[index] +
          this.totalRestaurantsPricePerExperience[index]
      );
    },
    displayExperiences() {
      return [ ...this.experiences ].sort((experienceA, experienceB) => {
        return experienceB.quality_score - experienceA.quality_score;
      });
    },
  },
  methods: {
    optimizeVideo: optimizeVideo,
    ...mapMutations([ 'setDestinationBackground' ]),

    transformImage: transformImage,
    toggleReadmore() {
      this.readMore = true;
      this.$emit('readmore-toggled', this.readMore);
    },
    handleDiscoverCity(name) {
      this.$router.push({
        path: '/destination/' + name,
        query: { ...this.$route.query, mode: 'Discover' },
      });
    },
  },
  components: {
    // RlCarouselSlide,
    // Carousel,
    Story,
  },
};
</script>

<style lang="scss" scoped>
.feed-not-expanded * {
  max-height: calc(100vh - 100px);
}
</style>
<style lang="scss" scoped>
.hide {
  display: none !important;
}

.slide {
  width: 100vw;
}

.city-experiences-container {
  width: 100%;
}

.city-section {
  display: flex;
  flex-direction: column;
  .city-title-container {
    height: 1.8vw;
    margin-bottom: 1.3vw;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    cursor: pointer;

    .city-title {
      font-family: PoppinsSemiBold;
      margin-right: 0.4vw;
      font-size: 1.6vw;
      color: #28262e;
    }

    .active {
      color: #d62a53;
    }

    .discover {
      font-family: MontserratRegular;
      font-size: 0.9vw;
      color: #d62a53;
    }
  }

  .city-details-container {
    height: 2.5vw;
    display: flex;
    flex-direction: row;
    margin-bottom: 1.67vw;

    .city-detail {
      height: 100%;
      border-radius: 1.25vw;
      border: solid 1px #d7d7d7;
      background-color: #fff;
      padding: 0 1.25vw;
      margin-right: 0.4vw;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: #28262e;
      font-family: MontserratRegular;
      font-size: 0.93vw;
      letter-spacing: -0.03vw;

      .city-detail-icon {
        width: 0.93vw;
        height: 0.93vw;
        margin-right: 0.4vw;
        background-repeat: no-repeat;
        background-size: contain;
        filter: brightness(0) saturate(100%) invert(48%) sepia(4%)
          saturate(319%) hue-rotate(212deg) brightness(92%) contrast(96%);
      }
    }
  }

  .city-experiences-container {
    width: 100%;

    .swiper {
      height: 100%;

      .hooper-next {
        background-color: #d7d7d7;
        border-radius: 100px;
      }

      .hooper-prev {
        background-color: #d7d7d7;
        border-radius: 100px;
        margin-left: -1.3vw;
      }

      .hooper-slide > .slide > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.title {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.02em;
  margin-bottom: 12px;
}

.location {
  svg {
    margin-right: 4px;
  }
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.9);
}

.description-left {
  width: 50%;
  position: absolute;
  left: 21px;
  bottom: unset !important;
}

.v-btn {
  bottom: 100px;
  left: calc(50% + 44px);
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #d62a53;
  height: 48px !important;
  max-height: 48px !important;
  box-shadow: none !important;
  box-shadow: 12px !important;
}
</style>
