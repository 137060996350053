var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"city-section hidden-xs-only",class:{ hide: _vm.displayExperiences.length < 1 },style:({
    height: _vm.height,
  })},[_c('div',{staticClass:"city-experiences-container tw-relative"},[(_vm.displayExperiences.length > 0)?[(false)?[(_vm.storyItems[0].type == 'image')?_c('v-img',{staticClass:"v-img",style:({
            height: _vm.height,
          }),attrs:{"src":_vm.transformImage(_vm.storyItems[0].url)}}):_vm._e(),(_vm.storyItems[0].type == 'video')?_c('video',{staticClass:"v-img",style:({
            height: _vm.height,
          }),attrs:{"preload":"","src":_vm.optimizeVideo(_vm.storyItems[0].url)}}):_vm._e()]:_vm._e(),_c('div',[_c('Story',{ref:"story",staticClass:"story",attrs:{"isMobile":true,"showButtons":false,"items":_vm.storyItems,"storyId":_vm.displayExperiences[0].exp_id,"active":_vm.active,"influencer":_vm.displayExperiences[0].influencer_data,"expandStoryCarouselEnabled":false},on:{"click":e => _vm.$emit('click', e),"toggle-expand":() => {}}})],1),_c('div',{staticClass:"description-left"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.displayExperiences[0].name))]),_c('div',{staticClass:"location"},[_c('svg',{attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.4922 4.99805C10.4922 8.49805 5.99219 11.498 5.99219 11.498C5.99219 11.498 1.49219 8.49805 1.49219 4.99805C1.49219 3.80457 1.96629 2.65998 2.81021 1.81607C3.65412 0.972153 4.79871 0.498047 5.99219 0.498047C7.18566 0.498047 8.33025 0.972153 9.17417 1.81607C10.0181 2.65998 10.4922 3.80457 10.4922 4.99805Z","stroke":"white","stroke-opacity":"0.9","stroke-width":"0.82153","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M5.99219 6.49805C6.82061 6.49805 7.49219 5.82647 7.49219 4.99805C7.49219 4.16962 6.82061 3.49805 5.99219 3.49805C5.16376 3.49805 4.49219 4.16962 4.49219 4.99805C4.49219 5.82647 5.16376 6.49805 5.99219 6.49805Z","stroke":"white","stroke-opacity":"0.9","stroke-width":"0.82153","stroke-linecap":"round","stroke-linejoin":"round"}})]),_vm._v(" "+_vm._s(_vm.city ? _vm.city.name + ', ' + _vm.city.country : _vm.displayExperiences[0].city_name + ', ' + _vm.displayExperiences[0].country)+" ")])]),_c('v-btn',{staticClass:"discover-btn v-btn",on:{"click":function($event){return _vm.$router.push({
            name: 'MobilePackage',
            params: { experienceId: _vm.displayExperiences[0].exp_id },
          })}}},[_vm._v("Discover Trip")])]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }