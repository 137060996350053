var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.expanded ? 'feed-expanded' : 'feed-not-expanded'},[_c('div',{staticClass:"exit",on:{"click":() => {
        _vm.expanded = !_vm.expanded;
      }}},[_c('Exit')],1),_c('div',{ref:"cityData",staticClass:"page-container mobile-feed hidden-xs-only",class:_vm.expanding ? 'transition-none' : '',on:{"touchstart":_vm.handleTouchStart,"touchmove":_vm.handleTouch,"touchend":_vm.handleTouchEnd}},[(_vm.trips)?_c('div',{staticClass:"list-container"},_vm._l((_vm.trips),function(trip,index){return _c('div',{key:'details' + index},[(index < _vm.activeIndex - 1)?_c('div',{style:({
            height: _vm.imageHeight,
            marginTop:
              index === 0
                ? 'calc(' +
                  _vm.activeIndex * -100 +
                  'vh' +
                  ' + ' +
                  _vm.movePercentage * 100 +
                  '%' +
                  ' + ' +
                  _vm.activeIndex * _vm.headerHeight +
                  'px)'
                : 0,
          })}):(index <= _vm.activeIndex + 1)?_c('city-section',{staticClass:"city-section",style:({
            marginTop:
              index === 0
                ? 'calc(' +
                  _vm.activeIndex * -100 +
                  'vh' +
                  ' + ' +
                  _vm.movePercentage * 100 +
                  '%' +
                  ' + ' +
                  _vm.activeIndex * _vm.headerHeight +
                  'px)'
                : 0,
          }),attrs:{"city":null,"experiences":[trip],"maxPrice":_vm.maxAvailablePrice,"active":index === _vm.activeIndex,"minPrice":_vm.minAvailablePrice,"height":_vm.imageHeight,"minFlightPrice":typeof _vm.cityMinimumFlightPrices[index] === 'number'
              ? _vm.cityMinimumFlightPrices[index]
              : null,"flightDuration":_vm.cityMinimumFlightDuration[index]},on:{"click":() => {
              _vm.expanded = true;
            }}}):_vm._e()],1)}),0):_c('div',{staticClass:"list-container"},_vm._l((_vm.displayCities),function(city,index){return _c('div',{key:'details' + city.city_id + index,ref:city.city_id,refInFor:true},[(index < _vm.activeIndex - 1)?_c('div',{style:({
            height: _vm.imageHeight,
            marginTop:
              index === 0
                ? 'calc(' +
                  _vm.activeIndex * -100 +
                  'vh' +
                  ' + ' +
                  _vm.movePercentage * 100 +
                  '%' +
                  ' + ' +
                  _vm.activeIndex * _vm.headerHeight +
                  'px)'
                : 0,
          })}):(index <= _vm.activeIndex + 1)?_c('city-section',{staticClass:"city-section",style:({
            marginTop:
              index === 0
                ? 'calc(' +
                  _vm.activeIndex * -100 +
                  'vh' +
                  ' + ' +
                  _vm.movePercentage * 100 +
                  '%' +
                  ' + ' +
                  _vm.activeIndex * _vm.headerHeight +
                  'px)'
                : 0,
          }),attrs:{"city":city,"experiences":city.experiences,"maxPrice":_vm.maxAvailablePrice,"active":index === _vm.activeIndex,"minPrice":_vm.minAvailablePrice,"height":_vm.imageHeight,"minFlightPrice":typeof _vm.cityMinimumFlightPrices[index] === 'number'
              ? _vm.cityMinimumFlightPrices[index]
              : null,"flightDuration":_vm.cityMinimumFlightDuration[index]},on:{"click":() => {
              _vm.expanded = true;
            }}}):_vm._e()],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }